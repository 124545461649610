import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import HeaderSmall from '../components/HeaderSmall'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AuthorsList from '../components/AuthorsList';
import infoJson from '../data/info.json';
import { Divider } from '@material-ui/core';


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #000',
    textTransform: 'uppercase',
    paddingBottom: '10px',
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

function createData(name, unit, description) {
  return { name, unit, description };
}


function createResults(rank, name, global_rmse, pairwise_rmse, recall, avg_re, avg_te) {
  return { rank, name, global_rmse, pairwise_rmse, recall, avg_re, avg_te };
}

const metricsRows = [
  createData(
    'Global RMSE',
    'Meters',
    'Measures the Root Mean Squared Error (RMSE) across all fragments in the global coordinate system, calculating the distance between ground truth points and their closest points in the estimation of aligned fragments. This is the main metric that determines the winner of the challenge.'
  ),
  createData(
    'Outlier Detection F1 Score',
    'Percentage',
    'Measures how accurately participants detect outlier nodes.'
  ),
  createData(
    'Average Translation Error',
    'Meters',
    'The averaged translation error for the estimated transformation of each fragment pair in the scene.'
  ),
  createData(
    'Average Rotation Error',
    'Degrees',
    'The averaged rotation error in degrees for the estimated transformation of each fragment pair in the scene.'
  ),
];


const results = [
  createResults(
    '1st (*)',
    'SPARK LAB, MIT',
    '0.056',
    '0.065',
    '83.33',
    '0.23',
    '0.01'
  ),
  createResults(
    '2nd',
    'THE UNIVERSITY OF HONG KONG',
    '2.414',
    '0.281',
    '70.01',
    '0.23',
    '0.02'
  ),
  createResults(
    '3rd',
    '-',
    '7.085',
    '2.006',
    '-',
    '-',
    '-'
  ),
  createResults(
    '4th',
    '-',
    '14.734',
    '4.329',
    '58.93',
    '0.19',
    '0.02'
  ),
  createResults(
    '5th',
    '-',
    '14.753',
    '1.471',
    '50.71',
    '0.01',
    '0.02'
  ),
];

export default function Challenge2025() {
  // get the challenge id from the url
  // let { id } = useParams();

  return (
    <React.Fragment>
      <HeaderSmall title={"Nothing Stands Still Challenge 2025"} subTitle={"An initiative of HILTI and the Gradient Spaces group"} />

      <div style={{ "backgroundColor": "white", "paddingTop": "20px", "paddingBottom": "50px" }} >

        <Container maxWidth="md">
          <Box sx={{ my: 0 }}>

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Overview
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              Together with HILTI, we are tackling the critical challenge of seamlessly integrating progress scans from various stages of construction toward accurate progress monitoring and prediction. To this end, we are bringing Nothing Stands Still to the <Link href="https://2025.ieee-icra.org" target="_blank">2025 International Conference on Robotics and Automation</Link> (ICRA), and specifically as a challenge at the <Link href="https://construction-robots.github.io/" target="_blank">4th Workshop on Future of Construction</Link>.
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The <b>Nothing Stands Still 2025 Challenge</b> targets the task of multiway spatiotemporal 3D point cloud registration of data collected over time at construction sites. For more details on the data, we refer to the <Link href="https://arxiv.org/pdf/2311.09346.pdf" target="_blank">"Nothing Stands Still: A spatiotemporal benchmark on 3D point cloud registration"</Link> paper that contains all information about the dataset acquisition and curation, as well as on the benchmark associated with it.
            </Typography>

            <Image src="images/challenge-2025-teaser.png" aspectRatio={2000 / 1294} color={"none"} style={{ "marginTop": "10px", "marginBottom": "10px" }} />



            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Important Dates
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              <ul>
                <li><b>- Challenge Announced :</b> March 6th, 2025</li>
                <li><b>- Submission Deadline :</b> May 7th, 2025, 11:59pm PST</li>
                <li><b>- Winner Announcement :</b> Award ceremony and winning presentations will be held at the ICRA 2025 Future of Construction Workshop. A prize of 4K USD will be awarded to the winner and 1K USD to the runner up.</li>
              </ul>
            </Typography>





            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Evaluation Protocol
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The goal of the challenge is to achieve a global spatiotemporal map of 3D fragments collected at any time and location at the same construction scenes, as the latter evolve.
              Participants will be evaluated on the cross-area split of the Nothing Stands Still dataset for the multiway registration task and particularly on the metric of Global Root Mean Squared Error (RMSE) of each scene, which we will use to select the winner. 
              This new data split is designed to evaluate the generalization capabilities of the algorithms to unseen areas. In addition, a secondary task of outlier detection will be used to evaluate robustness of the algorithms.
              The winner and the first runner up will receive a cash prize (4K USD and 1K USD respectively). Everybody is welcome to participate in the challenge, however only students (undergrad, postgrad) are eligible for receiving the cash prizes.
              Below, we provide the details of Global RMSE and other metrics used for evaluating algorithmic behavior.
            </Typography>

            <TableContainer style={{ marginTop: "20px" }}>
              <Table aria-label="metrics-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ minWidth: '250px' }}>Metric</StyledTableCell>
                    <StyledTableCell>Unit</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metricsRows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row" style={{ fontWeight: 500 }}>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.unit}</StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "20px" }}>
              For more details on the metrics and evaluation, we refer to Section 5 of the <Link href="https://arxiv.org/pdf/2311.09346.pdf" target="_blank">"Nothing Stands Still: A spatiotemporal benchmark on 3D point cloud registration"</Link> paper.
            </Typography>





            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Dataset
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The data of this challenge is the set of raw 3D fragments and their corresponding temporal stamps. Since there is no odometry information due to the way the dataset was collected, in lieu of that we provide participants with a graph structure, where nodes represent the spatiotemporal fragments and edges denote spatial overlap.
              We will be referring to them as "odometry" graphs.
              The test set for the challenge is hidden and evaluation on it is hosted on our <Link href="" target='_blank'>evaluation server</Link> <i>(coming soon!)</i>.
              For the training and validation sets, we provide all ground truth information.
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              In this <Link href="" target="_blank">Github repository</Link> <i>(coming soon!)</i> we provide the evaluation code and metrics for the multiway registration task. In addition to the main and defining metric of global pose error (RMSE), we also provide participants an evaluation on the rest of the metrics describted above, so as to furhter analyze the behavior of their algorithm.
            </Typography>

            <Typography variant="h6" align="center" component="h3" style={{ "paddingTop": "30px" }}>
              Download the Dataset
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              A new sampling of the NSS dataset is provided for non-commercial research purposes (no R&D) and can be downloaded from <Link href="https://tp-public-facing.s3.eu-north-1.amazonaws.com/Challenges/2025/NSS_challenge_2025.zip" target="_blank">here</Link>.
              Note both the data and benchmark are different from the 2024 NSS Challenge, to participate in this year's challenge only this year's data is relevant:
              <pre>
                - pointclouds.zip:         contains the 3D fragments<br />
                {/* - annotation_multiway.zip: ground truth pose annotations<br /> */}
                - pose_graphs.zip:         annotations for multiway registration<br />
                - visualizations.zip:      shows the relevant area<br />
                - nss_challenge_readme.md: information on the challenge and data structure<br />
              </pre>
            </Typography>

            <Typography variant="h6" align="center" component="h3" style={{ "paddingTop": "30px" }}>
              Data Evaluation
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              Data evaluation is hosted on our <Link href="" target='_blank'>evaluation server</Link> <i>(coming soon!)</i>.
              Users submit their results in a specific format and get the results back once processed. Details on the submission format and evaluation can be found on our evaluation server.
            </Typography>


             <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Previous Challenges 
            </Typography>
            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              Check out last year's challenge and winners: <Link href="/challenge_2024" target="_blank"> 2024 NSS Challenge </Link>
            </Typography>


            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Contact
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              For any questions, please contact us at <Link href="mailto:nss.benchmark@gmail.com" target="_blank"> nss.benchmark@gmail.com </Link> or submit an issue on the Github repository. We will be happy to help you!
              If you want to check more about the research we do, visit our group's website:
            </Typography>
            <Link href="https://gradientspaces.stanford.edu/" target="_blank"><Image src="images/GRADIENT_SPACES.gif" aspectRatio={1080 / 304} color={"none"} style={{ "margin": "auto", "marginTop": "30px", "marginBottom": "10px", "width": "50%" }} /></Link>




            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Organizers
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <AuthorsList authors={infoJson.organizers_2025} />


          </Box>
        </Container>

      </div>
    </React.Fragment>
  );
}
