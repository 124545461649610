import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import HeaderSmall from '../components/HeaderSmall'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AuthorsList from '../components/AuthorsList';
import infoJson from '../data/info.json';
import { Divider } from '@material-ui/core';


const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderBottom: '1px solid #000',
    textTransform: 'uppercase',
    paddingBottom: '10px',
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

function createData(name, unit, description) {
  return { name, unit, description };
}


function createResults(rank, name, global_rmse, pairwise_rmse, recall, avg_re, avg_te) {
  return { rank, name, global_rmse, pairwise_rmse, recall, avg_re, avg_te };
}

const metricsRows = [
  createData(
    'Global RMSE',
    'Meters',
    'Measures the Root Mean Squared Error (RMSE) across all fragments in the global coordinate system, calculating the distance between ground truth points and their closest points in the estimation of aligned fragments. This is the main metric that determines the winner of the challenge.'
  ),
  createData(
    'Pairwise RMSE',
    'Meters',
    'Measures the RMSE for each pair of fragments in the scene, between ground truth points and their closest points in the estimation of aligned fragment, averaged across all pairs.'
  ),
  createData(
    'Registration Recall',
    'Percentage',
    'The proportion of fragment pairs in the scene for which the estimated transformation closely matches the ground truth, within a predefined threshold.'
  ),
  createData(
    'Average Translation Error',
    'Meters',
    'The averaged translation error for the estimated transformation of each fragment pair in the scene.'
  ),
  createData(
    'Average Rotation Error',
    'Degrees',
    'The averaged rotation error in degrees for the estimated transformation of each fragment pair in the scene.'
  ),
];


const results = [
  createResults(
    '1st (*)',
    'SPARK LAB, MIT',
    '0.056',
    '0.065',
    '83.33',
    '0.23',
    '0.01'
  ),
  createResults(
    '2nd',
    'THE UNIVERSITY OF HONG KONG',
    '2.414',
    '0.281',
    '70.01',
    '0.23',
    '0.02'
  ),
  createResults(
    '3rd',
    '-',
    '7.085',
    '2.006',
    '-',
    '-',
    '-'
  ),
  createResults(
    '4th',
    '-',
    '14.734',
    '4.329',
    '58.93',
    '0.19',
    '0.02'
  ),
  createResults(
    '5th',
    '-',
    '14.753',
    '1.471',
    '50.71',
    '0.01',
    '0.02'
  ),
];

export default function Challenge2024() {
  // get the challenge id from the url
  // let { id } = useParams();

  return (
    <React.Fragment>
      <HeaderSmall title={"Nothing Stands Still Challenge 2024"} subTitle={"An initiative of HILTI and the Gradient Spaces group"} />

      <div style={{ "backgroundColor": "white", "paddingTop": "20px", "paddingBottom": "50px" }} >

        <Container maxWidth="md">
          <Box sx={{ my: 0 }}>

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Overview
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              Together with HILTI, we are tackling the critical challenge of seamlessly integrating progress scans from various stages of construction toward accurate progress monitoring and prediction. To this end, we are bringing Nothing Stands Still to the <Link href="https://2024.ieee-icra.org" target="_blank">2024 International Conference on Robotics and Automation</Link> (ICRA), and specifically as a challenge at the <Link href="https://construction-robots.github.io/" target="_blank">3rd Workshop on Future of Construction</Link>.
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The <b>Nothing Stands Still 2024 Challenge</b> targets the task of multiway spatiotemporal 3D point cloud registration of data collected over time at construction sites. For more details on the data, we refer to the <Link href="https://arxiv.org/pdf/2311.09346.pdf" target="_blank">"Nothing Stands Still: A spatiotemporal benchmark on 3D point cloud registration"</Link> paper that contains all information about the dataset acquisition and curation, as well as on the benchmark associated with it.
            </Typography>

            <Image src="images/challenge-teaser.jpg" aspectRatio={1200 / 527} color={"none"} style={{ "marginTop": "10px", "marginBottom": "10px" }} />



            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Important Dates
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              <ul>
                <li><b>- Challenge Announced :</b> Feb 15th, 2024</li>
                <li><b>- Submission Deadline :</b> May 1st, 2024, 11:59pm PST</li>
                <li><b>- Winner Announcement :</b> Award ceremony and winning presentations will be held at the ICRA 2024 Future of Construction Workshop. A prize of 4K CHF will be awarded to the winner and 1K CHF to the runner up.</li>
              </ul>
            </Typography>





            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Evaluation Protocol
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The goal of the challenge is to achieve a global spatiotemporal map of 3D fragments collected at any time and location at the same construction scenes, as the latter evolve.
              Participants will be evaluated on the original split of the Nothing Stands Still dataset for the multiway registration task and particularly on the metric of Global Root Mean Squared Error (RMSE) of each scene, which we will use to select the winner.
              The winner and the first runner up will receive a cash prize (4K CHF and 1K CHF respectively). Everybody is welcome to participate in the challenge, however only students (undergrad, postgrad) are eligible for receiving the cash prizes.
              Below, we provide the details of Global RMSE and other metrics used for evaluating algorithmic behavior.
            </Typography>

            <TableContainer style={{ marginTop: "20px" }}>
              <Table aria-label="metrics-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ minWidth: '250px' }}>Metric</StyledTableCell>
                    <StyledTableCell>Unit</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metricsRows.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell component="th" scope="row" style={{ fontWeight: 500 }}>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.unit}</StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "20px" }}>
              The threshold for the registration recall metric is set to 0.1 meters for the translation error and 10 degrees for the rotation error.
              For more details on the metrics and evaluation, we refer to Section 5 of the <Link href="https://arxiv.org/pdf/2311.09346.pdf" target="_blank">"Nothing Stands Still: A spatiotemporal benchmark on 3D point cloud registration"</Link> paper.
            </Typography>





            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Dataset
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The data of this challenge is the set of raw 3D fragments and their corresponding temporal stamps. Since there is no odometry information due to the way the dataset was collected, in lieu of that we provide participants with a graph structure, where nodes represent the spatiotemporal fragments and edges denote spatial overlap.
              We will be referring to them as "odometry" graphs.
              The test set for the challenge is hidden and evaluation on it is hosted on our <Link href="https://eval.nothing-stands-still.com/" target='_blank'>evaluation server</Link>.
              For the training and validation sets, we provide all ground truth information.
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              In this <Link href="https://github.com/GradientSpaces/nss-challenge" target="_blank">Github repository</Link> we provide the evaluation code and metrics for the multiway registration task. In addition to the main and defining metric of global pose error (RMSE), we also provide participants an evaluation on the rest of the metrics describted above, so as to furhter analyze the behavior of their algorithm.
            </Typography>

            <Typography variant="h6" align="center" component="h3" style={{ "paddingTop": "30px" }}>
              Download the Dataset
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              The NSS dataset is provided for non-commercial research purposes (no R&D) and can be downloaded from <Link href="/download" target="_blank">here</Link>.
              Relevant data to download are:
              <pre>
                - pointclouds.zip:         contains the 3D fragments<br />
                {/* - annotation_multiway.zip: ground truth pose annotations<br /> */}
                - pose_graphs.zip:         annotations for multiway registration<br />
              </pre>
              Note that there is a difference between annotation.zip and annotation_multiway.zip, so make sure you download the appropriate files. Also, please note that only the <i>original</i> folder is relevant (ignore other folders)
            </Typography>

            <Typography variant="h6" align="center" component="h3" style={{ "paddingTop": "30px" }}>
              Data Evaluation
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              Data evaluation is hosted on our <Link href="https://eval.nothing-stands-still.com/" target='_blank'>evaluation server</Link>.
              Users submit their results in a specific format and get the results back once processed. Details on the submission format and evaluation can be found on our evaluation server.
            </Typography>


             <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              2024 Challenge Winners 
            </Typography>

            <TableContainer style={{ marginTop: "20px" }}>
              <Table aria-label="results-table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell> Rank</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Global RMSE (m)</StyledTableCell>
                    <StyledTableCell>Pairwise RMSE (m)</StyledTableCell>
                    <StyledTableCell>Recall</StyledTableCell>
                    <StyledTableCell>Avg. RE</StyledTableCell>
                    <StyledTableCell>Avg. TE</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((row) => (
                    <TableRow key={row.rank}>
                     
                      <StyledTableCell>{row.rank}</StyledTableCell>
                      <StyledTableCell>{row.name}</StyledTableCell>
                      <StyledTableCell>{row.global_rmse}</StyledTableCell>
                      <StyledTableCell>{row.pairwise_rmse}</StyledTableCell>
                      <StyledTableCell>{row.recall}</StyledTableCell>
                      <StyledTableCell>{row.avg_re}</StyledTableCell>
                      <StyledTableCell>{row.avg_te}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              <br /><b><u>WINNER NAMES</u></b><br />
               - <b>SPARK LAB, MIT</b>: Hyungtae Lim+, Jingnan Shi+, Yun Chang+, Lukas Schmid+, Prof. Luca Carlone<br /> 
               - <b>THE UNIVERSITY OF HONG KONG</b>: Yinqiang Zhang, Liang Lu, Prof. Jia Pan<br />
            </Typography>
            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "100px"},{"fontSize" : "10pt" }}>  
               <i>("+" denotes equal contribution)</i>
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "100px"},{"fontSize" : "10pt" }}>
              <br />(*) <i>The 1st winners utilized training and validation set data points as anchors to register the test set data points, hence the very low errors. The 2nd winners utilized, as per the scope of the challenge, only the test data points without any prior knowledge of the space.</i>
            </Typography>

            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Contact
            </Typography>

            <Typography variant="body1" align="left" component="h2" style={{ "paddingTop": "10px" }}>
              For any questions, please contact us at <Link href="mailto:nss.benchmark@gmail.com" target="_blank"> nss.benchmark@gmail.com </Link> or submit an issue on the Github repository. We will be happy to help you!
              If you want to check more about the research we do, visit our group's website:
            </Typography>
            <Link href="https://gradientspaces.stanford.edu/" target="_blank"><Image src="images/GRADIENT_SPACES.gif" aspectRatio={1080 / 304} color={"none"} style={{ "margin": "auto", "marginTop": "30px", "marginBottom": "10px", "width": "50%" }} /></Link>




            <Typography variant="h5" align="center" component="h2" style={{ "paddingTop": "40px" }}>
              Organizers
            </Typography>
            <Divider style={{ "marginBottom": "10px" }} />

            <AuthorsList authors={infoJson.organizers_2024} />


          </Box>
        </Container>

      </div>
    </React.Fragment>
  );
}
