
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useToken from '../hocks/useToken';
import config from '../config.json';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `0px solid ${theme.palette.divider}`,
    minHeight: '35px',
    // set appbar blur
    backdropFilter: 'blur(5px)',
    // set appbar opacity
    opacity: '0.9',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    flexGrow: 1,
    letterSpacing: '0.1em',
    margin: theme.spacing(1, 1.5),
  },
  link: {
    color: 'primary',
    fontSize: '13px',
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  MenuItem: {
    fontSize: '14px',
  }
}));

export default function TopBar() {
  // Get token from local storage
  const { token, setToken } = useToken();

  // Get style definition
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    console.log(event);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    setToken(null);
    await fetch(config.local.api_base + '/auth/logout/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Token ' + token.userKey,
      }
    })
      .then(response => response.json())
      .then(response => console.log(JSON.stringify(response)));


  };

  return (
    <AppBar position="static" color="transparent" style={{ backdropFilter: "blur(20px)" }} elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar} variant="dense" style={{ marginTop: "0" }}>
        <Link href="/" variant="button" color="inherit" noWrap className={classes.toolbarTitle}>
          NSS BENCHMARK
        </Link>
        <nav>
          <Link variant="button" color="inherit" href="https://arxiv.org/abs/2311.09346" className={classes.link}>
            Paper <OpenInNewIcon fontSize='inherit' />
          </Link>
          <Link variant="button" color="inherit" href="#" className={classes.link}>
            Code <OpenInNewIcon fontSize="inherit" />
          </Link>
          <Link variant="button" color="inherit" href="/viewer" className={classes.link}>
            Visualization
          </Link>
          <Link variant="button" color="inherit" href="/download" className={classes.link}>
            Download
          </Link>
          {/* <Link variant="button" color="inherit" href="/leaderboard" className={classes.link}>
            Leaderboard
          </Link> */}
          <Link variant="button" color="inherit" href="/challenge" className={classes.link} style={{ fontWeight: 600 }}>
            CHALLENGE@ICRA2025
          </Link>
        </nav>
        {/* 
        <div>
          <IconButton
            size="small"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <AccountCircle fontSize="small" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {token && [
              <Box style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant='h6' className={classes.MenuItem} >{token.username}</Typography>
                <Typography color='textSecondary' variant="caption" style={{ fontSize: "12px" }} className={classes.MenuItem} >Participant</Typography>
              </Box>,
              <Divider />,
              <MenuItem component="a" onClick={handleClose} className={classes.MenuItem} href="/profile" >Profile</MenuItem>,
              <MenuItem onClick={handleLogout} className={classes.MenuItem}>Log out</MenuItem>
            ]}
            {!token && [
              <Box style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '5px', paddingBottom: '5px' }}>
                <Typography variant='h6' className={classes.MenuItem} >You haven't logged in.</Typography>
              </Box>,
              <Divider />,
              <MenuItem component="a" onClick={handleClose} className={classes.MenuItem} href="/login" >Log in</MenuItem>,
              <MenuItem component="a" onClick={handleClose} className={classes.MenuItem} href="/signup" >Sign up</MenuItem>
            ]}
          </Menu>
        </div> */}

      </Toolbar>
    </AppBar >
  );
}